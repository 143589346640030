
@font-face {
  font-family: 'Roboto';
  src: url('./styles/fonts/Roboto-MediumItalic.eot');
  src: local('Roboto Medium Italic'), local('Roboto-MediumItalic'),
  url('./styles/fonts/Roboto-MediumItalic.eot?#iefix') format('embedded-opentype'),
  url('./styles/fonts/Roboto-MediumItalic.woff') format('woff'),
  url('./styles/fonts/Roboto-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: url('./styles/fonts/Roboto-Italic.eot');
  src: local('Roboto Italic'), local('Roboto-Italic'),
  url('./styles/fonts/Roboto-Italic.eot?#iefix') format('embedded-opentype'),
  url('./styles/fonts/Roboto-Italic.woff') format('woff'),
  url('./styles/fonts/Roboto-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: url('./styles/fonts/Roboto-Bold.eot');
  src: local('Roboto Bold'), local('Roboto-Bold'),
  url('./styles/fonts/Roboto-Bold.eot?#iefix') format('embedded-opentype'),
  url('./styles/fonts/Roboto-Bold.woff') format('woff'),
  url('./styles/fonts/Roboto-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('./styles/fonts/Roboto-Regular.eot');
  src: local('Roboto'), local('Roboto-Regular'),
  url('./styles/fonts/Roboto-Regular.eot?#iefix') format('embedded-opentype'),
  url('./styles/fonts/Roboto-Regular.woff') format('woff'),
  url('./styles/fonts/Roboto-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('./styles/fonts/Roboto-Medium.eot');
  src: local('Roboto Medium'), local('Roboto-Medium'),
  url('./styles/fonts/Roboto-Medium.eot?#iefix') format('embedded-opentype'),
  url('./styles/fonts/Roboto-Medium.woff') format('woff'),
  url('./styles/fonts/Roboto-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('./styles/fonts/Roboto-BoldItalic.eot');
  src: local('Roboto Bold Italic'), local('Roboto-BoldItalic'),
  url('./styles/fonts/Roboto-BoldItalic.eot?#iefix') format('embedded-opentype'),
  url('./styles/fonts/Roboto-BoldItalic.woff') format('woff'),
  url('./styles/fonts/Roboto-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: url('./styles/fonts/Roboto-ThinItalic.eot');
  src: local('Roboto Thin Italic'), local('Roboto-ThinItalic'),
  url('./styles/fonts/Roboto-ThinItalic.eot?#iefix') format('embedded-opentype'),
  url('./styles/fonts/Roboto-ThinItalic.woff') format('woff'),
  url('./styles/fonts/Roboto-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: url('./styles/fonts/Roboto-Black.eot');
  src: local('Roboto Black'), local('Roboto-Black'),
  url('./styles/fonts/Roboto-Black.eot?#iefix') format('embedded-opentype'),
  url('./styles/fonts/Roboto-Black.woff') format('woff'),
  url('./styles/fonts/Roboto-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('./styles/fonts/Roboto-Light.eot');
  src: local('Roboto Light'), local('Roboto-Light'),
  url('./styles/fonts/Roboto-Light.eot?#iefix') format('embedded-opentype'),
  url('./styles/fonts/Roboto-Light.woff') format('woff'),
  url('./styles/fonts/Roboto-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('./styles/fonts/Roboto-LightItalic.eot');
  src: local('Roboto Light Italic'), local('Roboto-LightItalic'),
  url('./styles/fonts/Roboto-LightItalic.eot?#iefix') format('embedded-opentype'),
  url('./styles/fonts/Roboto-LightItalic.woff') format('woff'),
  url('./styles/fonts/Roboto-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: url('./styles/fonts/Roboto-BlackItalic.eot');
  src: local('Roboto Black Italic'), local('Roboto-BlackItalic'),
  url('./styles/fonts/Roboto-BlackItalic.eot?#iefix') format('embedded-opentype'),
  url('./styles/fonts/Roboto-BlackItalic.woff') format('woff'),
  url('./styles/fonts/Roboto-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: url('./styles/fonts/Roboto-Thin.eot');
  src: local('Roboto Thin'), local('Roboto-Thin'),
  url('./styles/fonts/Roboto-Thin.eot?#iefix') format('embedded-opentype'),
  url('./styles/fonts/Roboto-Thin.woff') format('woff'),
  url('./styles/fonts/Roboto-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}


body {
  padding: 0;
  margin: 0;
  overflow-x: hidden;
}

body::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

body::-webkit-scrollbar-thumb {
  background: #ADB5BD;
}

body::-webkit-scrollbar-thumb:hover {
  background: #6C757D;
}

body::-webkit-scrollbar-track {
  background: #FFFFFF;
  box-shadow: inset 7px 10px 12px #EAEAEA;
}

body {
  width: 100%;
  margin: 0;
  font-family: 'Roboto',sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #FFFFFF;
}

a {
  color: inherit;
  text-decoration: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button, a, p, div {
  color: #3D3D3D;
}

* {
  box-sizing: border-box;
  font-family: Roboto, sans-serif;
  letter-spacing: 0.15px;
}
